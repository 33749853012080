/* Buttons */

.btn {
  all: unset;
  border: 2px solid;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
  color: var(--color-white);
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: .5rem 3rem;
  text-transform: uppercase;
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  user-select: none;
}

.btn--block {
  display: block;
  width: 100%;
  text-align: center;
}

.btn--floating {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  bottom: 2rem;
  display: flex;
  font-size: 1.3em;
  padding: 1rem;
  position: fixed;
}

.btn--floating-right {
  right: 2rem;
}

.btn--floating-left {
  left: 2rem;
}

.btn:active {
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
}

.btn-primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.btn-primary--outline {
  background-color: transparent;
  border-color: var(--color-white);
}

/* Navbar */

.navbar {
  align-items: center;
  background-color: var(--color-primary);
  margin-bottom: 1rem;
  height: 50px;
}

.navbar__list {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: auto 0;
  padding: 0 2rem;
}

.navbar__item {
  color: var(--color-white);
  display: flex;
  font-size: 1.3em;
}

.navbar__text {
  font-weight: bold;
  margin: 0 0 0 1rem;
  text-transform: capitalize;
}

.navbar__link {
  cursor: pointer;
}

.navbar__link:hover {
  color: var(--color-dark-gray);
}

.alert {
  border: 1px solid;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  text-align: left;
  width: 100%;
}

.alert__title {
  color: var(--color-danger-border);
  margin: 0;
}

.alert__error {
  background-color: var(--color-danger-background);
  border-color: var(--color-danger-border);
}